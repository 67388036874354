import React from 'react';

const PriceIncreaseFilter = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      Toggle Price Increase Filter
    </button>
  );
};

export default PriceIncreaseFilter;
