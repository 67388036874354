// InstantTicketsComponent.js
import React from 'react';
import { Table } from 'react-bootstrap'; // Ensure you've imported Table from react-bootstrap
import styled from 'styled-components';

const ConditionalTd = styled.td`
    background-color: ${props => props.quantity === 0 ? 'red' : 'transparent'} !important;
    color: ${props => props.quantity === 0 ? 'white' : 'black'} !important;
    text-align: left;
`;

const InstantTicketsComponent = ({
    handleInstantTicketQuantityReset,
    handleInstantTicketQuantitySave,
    ticketData,
    handleInstantTicketUnActivatedQuantityChange,
    handleInstantTicketActivatedQuantityChange,
    handleInstantTicketCountChange,
}) => {
    return (
        <div>
            <div style={{ float: 'right' }}>
                <button className="btn btn-dark" onClick={handleInstantTicketQuantityReset}>Reset</button>
                <span> | </span>
                <button className="btn btn-dark" onClick={handleInstantTicketQuantitySave}>Save</button>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Ticket Price</th>
                        <th>Ticket Name</th>
                        <th>#Unactivated Packs</th>
                        <th>#Activated Packs</th>
                        <th>#Tickets in Pack</th>
                        <th>#Starting Count (Tray+Drawer)</th>
                        <th>#Current (Tray+Drawer)</th>
                        <th>#Sold</th>
                    </tr>
                </thead>
                <tbody>
                    {ticketData.map((item, index) => (
                        <tr key={index}>
                            <ConditionalTd quantity={item.unactivated_quantity}>{item.price}</ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>{item.name}</ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>
                                <input
                                    type="number"
                                    value={item.unactivated_quantity}
                                    onChange={(e) => handleInstantTicketUnActivatedQuantityChange(index, e.target.value)}
                                />
                            </ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>
                                <input
                                    type="number"
                                    value={item.activated_quantity}
                                    onChange={(e) => handleInstantTicketActivatedQuantityChange(index, e.target.value)}
                                />
                            </ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>{item.tickets_in_pack}</ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>{item.starting_count}</ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>
                                <input
                                    type="number"
                                    value={item.current_count}
                                    onChange={(e) => handleInstantTicketCountChange(index, e.target.value)}
                                />
                            </ConditionalTd>
                            <ConditionalTd quantity={item.unactivated_quantity}>{item.starting_count - item.current_count}</ConditionalTd>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default InstantTicketsComponent;
