// src/firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3CrsG9HHPQLcJMSzAQColNoMJIFMTJMQ",
  authDomain: "quickstop-app.firebaseapp.com",
  projectId: "quickstop-app",
  storageBucket: "quickstop-app.appspot.com",
  messagingSenderId: "1012110726483",
  appId: "1:1012110726483:web:c330abe25a2dc28762bda6",
  measurementId: "G-ELYME9FFM0"
};

const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase);

export default auth;
