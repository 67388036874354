// CustomNavbar.js
import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomNavbar = ({ user, navExpanded, toggleNavbar, handleNavSelect, invoiceOrders, handleFetchOrderDetails }) => {
    const [isSettingsVerified, setIsSettingsVerified] = useState(true); // Initially false
    const [isDisabled, setIsDisabled] = useState(false); // Initially false
    

    // Simulated function to verify settings
    const verifySettings = () => {
        setIsSettingsVerified(true); // You would replace this with your actual verification logic
    };

    return (
        <Navbar bg="light" expand="lg" expanded={navExpanded}>
            <Navbar.Brand href="#home">QuickStop</Navbar.Brand>
            <Navbar.Toggle onClick={toggleNavbar} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" onSelect={handleNavSelect}>
                {user && user.role === 'admin' && (
                    <>
                        <NavDropdown title="Admin" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#config"
                                    eventKey='config' >
                                        Settings
                                    </NavDropdown.Item>
                            <NavDropdown.Item href="#get-database"
                                        eventKey='get-database'
                                        disabled={!isSettingsVerified}>
                                        Planogram Database</NavDropdown.Item>
                            <NavDropdown.Item href="#get-price-list"
                                        eventKey='get-price-list'
                                        disabled={!isSettingsVerified}>
                                        Selling Price List</NavDropdown.Item>
                            <NavDropdown.Item href="#get-updated-invoices"
                                    eventKey='get-updated-invoices'
                                    disabled={!isSettingsVerified}>
                                    Agile Invoices</NavDropdown.Item>
                            <NavDropdown.Item href="#missing-items-agile"
                                    eventKey='missing-items-agile'
                                    disabled={!isSettingsVerified}>
                                    Missing Planogram Items in Agile</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Orders" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#verify-order"
                                    eventKey='verify-order'
                                    disabled={!isSettingsVerified}>
                                    Verify Latest Order</NavDropdown.Item>
                            <NavDropdown.Item href="#analyze-order"
                                    eventKey='analyze-order'
                                    disabled={!isSettingsVerified}>
                                    Analyze Latest Order</NavDropdown.Item>
                            <NavDropdown.Item href="#add-missing-items"
                                    eventKey='add-missing-items'
                                    disabled={!isSettingsVerified}>
                                    Add Latest Order Missing Items</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Stats" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#cost-price-db"
                                    eventKey='cost-price-db'
                                    disabled={isDisabled}>
                                    Cost Prices</NavDropdown.Item>
                            <NavDropdown.Item href="#updated-selling-price-db"
                                    eventKey='updated-selling-price-db'
                                    disabled={!isSettingsVerified}>
                                    Last Updated Selling Prices</NavDropdown.Item>
                            <NavDropdown.Item href="#selling-price-db"
                                    eventKey='selling-price-db'
                                    disabled={!isSettingsVerified}>
                                    Visualtouch Selling Prices</NavDropdown.Item>
                        </NavDropdown>
                    </>
                )}
                {user && (
                    <NavDropdown title="Planogram" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#viewer"
                            eventKey='viewer'
                            disabled={!isSettingsVerified}>
                            Viewer</NavDropdown.Item>
                        <NavDropdown.Item href="#scanner"
                            eventKey='scanner'
                            disabled={!isSettingsVerified}>
                            Scan Not Found Items</NavDropdown.Item>
                    </NavDropdown>
                )}
                {user && user.role === 'admin' && (
                    <NavDropdown title="Invoices" id="basic-nav-dropdown">
                        {invoiceOrders.map((order, index) => (
                            <NavDropdown.Item key={index} onClick={() => handleFetchOrderDetails(order)}
                                disabled={isDisabled}>
                                {order}
                            </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                )}
                {user && (
                    <NavDropdown title="Reports" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#instant_tickets"
                                eventKey='instant_tickets'
                                disabled={!isSettingsVerified}>
                                Instant Tickets</NavDropdown.Item>
                        <NavDropdown.Item href="#end_of_shift"
                                eventKey='end_of_shift'
                                disabled={!isSettingsVerified}>
                                End of Shift</NavDropdown.Item>
                        {user.role === 'admin' && (
                        <NavDropdown.Item href="#profit_calc"
                                eventKey='profit_calc'
                                disabled={!isSettingsVerified}>
                                Profit Calculator</NavDropdown.Item>
                        )}
                    </NavDropdown>
                )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default CustomNavbar;
