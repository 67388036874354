import React from 'react';
import Table from 'react-bootstrap/Table'; // Make sure to import Table from react-bootstrap

const ProductTable = ({ data }) => {

  const calculatePercentageChange = (costPrices) => {
    if (costPrices.length <= 1) return 'N/A'; // Return 'N/A' if there's not enough data to calculate

    const latestPrice = parseFloat(costPrices[costPrices.length - 1]);
    const lowestPrice = Math.min(...costPrices.map(price => parseFloat(price)));

    if (lowestPrice === 0) return 'N/A'; // Prevent division by zero

    const percentageChange = ((latestPrice - lowestPrice) / lowestPrice) * 100;
    return percentageChange.toFixed(2) + '%'; // Rounded to two decimal places
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>UPC</th>
          <th>Name</th>
          <th>Cost Prices</th>
          <th>Invoices</th>
          <th>%age Change</th> {/* New column header */}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.upc}>
            <td>{index + 1}</td>
            <td>{item.upc}</td>
            <td>{item.name}</td>
            <td>{item.cost_price.join(', ')}</td>
            <td>{item.invoices.join(', ')}</td>
            <td>{calculatePercentageChange(item.cost_price)}</td> {/* Displaying %age Change */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProductTable;
