// AnalyzeOrderComponent.js
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap'; // Ensure you've imported Tabs and Tab from react-bootstrap
import DynamicTable from '../Common/DynamicTable'; // Assuming DynamicTable is a component you've created for displaying data

const AnalyzeOrderComponent = ({ dropShippedItemsData, mismatchedItemsData, notDeliveredItemsData }) => {
    return (
        <div>
            <Tabs defaultActiveKey="dropShippedItems">
                <Tab eventKey="dropShippedItems" title="Drop Shipped">
                    <div className='table-section'>
                        <h>Drop Shipped Items in latest Invoice</h>
                        <DynamicTable data={dropShippedItemsData} />
                    </div>
                </Tab>
                <Tab eventKey="misMatchedItems" title="Mismatched">
                    <div className='table-section'>
                        <h>Mismatched Items in latest Invoice from the Placed Order</h>
                        <DynamicTable data={mismatchedItemsData} />
                    </div>
                </Tab>
                <Tab eventKey="notDeliveredItems" title="Not Delivered">
                    <div className='table-section'>
                        <h>Not Delivered Items in latest Invoice from the Placed Order</h>
                        <DynamicTable data={notDeliveredItemsData} />
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default AnalyzeOrderComponent;
