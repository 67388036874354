import React, { useState, useContext, useEffect } from 'react';
import auth from './firebaseConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import UserContext from './UserContext';
import { ADMIN_EMAIL } from './config.js';


const AuthenticationComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false); // false for Sign In, true for Sign Up
    const { user, setUser } = useContext(UserContext);
    const [message, setMessage] = useState(''); // State to store messages to the user

    // Function to handle automatic sign-out
    const handleAutoSignOut = () => {
        signOut(auth).then(() => {
            setUser(null); // Clear the user on sign out
            console.log("Automatically signed out due to inactivity.");
            setMessage("Automatically signed out due to inactivity.");
        }).catch((error) => {
            console.error("Error signing out:", error);
            setMessage("Error signing out: " + error.message);
        });
    };

    // Use useEffect to set up and clean up event listeners
    useEffect(() => {
        let signOutTimer;

        const resetTimer = () => {
            clearTimeout(signOutTimer);
            // Set to sign out after 1 hour of inactivity
            signOutTimer = setTimeout(handleAutoSignOut, 3600000); // 6000 ms = 1 mins
        };

        const events = ['mousemove', 'keypress', 'touchstart', 'touchmove'];

        // Add event listeners to detect user activity
        events.forEach(event => window.addEventListener(event, resetTimer));

        // Set initial timer
        resetTimer();

        // Clean up function to remove event listeners and clear timeout
        return () => {
            events.forEach(event => window.removeEventListener(event, resetTimer));
            clearTimeout(signOutTimer);
        };
    }, []); // Empty dependency array ensures this runs once on mount


    const toggleMode = () => {
        setIsSignUp(!isSignUp);
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Sign-in successful.
                const userRole = userCredential.user.email === ADMIN_EMAIL ? 'admin' : 'user';
                setUser({ ...userCredential.user, role: userRole });
                console.log("Signed in user:", userCredential.user);
                setMessage("");
            })
            .catch((error) => {
                console.error("Error signing in:", error);
                setMessage("Error signing in: " + error.message);
            });
    };
    

    const handleSignUp = (e) => {
        e.preventDefault();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Sign-up successful.
                console.log("Signed up user:", userCredential.user);
                setMessage("Signed up successfully. You can sign in now.");
            })
            .catch((error) => {
                console.error("Error signing up:", error);
                setMessage("Error signing up: " + error.message);
            });
    };
    
    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                setUser(null); // Clear the user on sign out
                setMessage("Signed out successfully.");
            })
            .catch((error) => {
                console.error("Error signing out:", error);
                setMessage("Error signing out: " + error.message);
            });
    };
    

    return (
        <div className="auth-container">
            {message && <div className="alert alert-info">{message}</div>}
            {!user ? (
                <div>
                <form onSubmit={isSignUp ? handleSignUp : handleSignIn} className="auth-form">
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control mb-2" placeholder="Email" />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mb-2" placeholder="Password" />
                    <button type="submit" className="btn btn-primary btn-block">
                        {isSignUp ? 'Sign Up' : 'Sign In'}
                    </button>
                </form>
                <button onClick={toggleMode} className="btn btn-link">
                    {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
                </button>
                </div>
            ) : (
                // Show user info and sign out button if signed in
                <div>
                    <p>Signed in as "{user.email}"
                    <button onClick={handleSignOut} className="btn btn-secondary">Sign Out</button>
                    </p>
                </div>
            )}
            

        </div>
    );
    
    
};

export default AuthenticationComponent;
