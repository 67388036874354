import React, { useState, useEffect, useRef } from 'react';
import Quagga from 'quagga';

const BarcodeScanner = () => {
  const [barcode, setBarcode] = useState('');
  const [isCameraActive, setIsCameraActive] = useState(false);
  const scannerRef = useRef(null);
  useEffect(() => {
      if (isCameraActive && scannerRef.current) {
          Quagga.init({
              inputStream: {
                  name: "Live",
                  type: "LiveStream",
                  target: scannerRef.current,
                  constraints: {
                      width: { min: 640 },
                      height: { min: 480 },
                      facingMode: "environment" // Use the rear-facing camera
                  },
              },
              locator: {
                  patchSize: "medium",
                  halfSample: true,
              },
              numOfWorkers: navigator.hardwareConcurrency,
              decoder: {
                  readers: [
                    "code_128_reader", 
                    "ean_reader", 
                    "ean_8_reader", 
                    "upc_reader",
                     "upc_e_reader"
                    ], // Specify barcode formats here
              },
              locate: true, // Enable locator for better accuracy
          }, (err) => {
              if (err) {
                  console.error(err);
                  setIsCameraActive(false); // Disable camera if there is an error
                  return;
              }
              Quagga.start();
          });

          Quagga.onDetected((data) => {
              setBarcode(data.codeResult.code);
              setIsCameraActive(false); // Add this line to stop the camera once a barcode is detected
          });

          return () => {
              Quagga.offDetected();
              Quagga.stop();
          };
      }
  }, [isCameraActive]); // Dependency on isCameraActive state

  return (
    <div>
      <button onClick={() => setIsCameraActive(true)}>Load Camera</button>
      <div ref={scannerRef} style={{ width: '100%' }}></div>
      {barcode && <p>Scanned Barcode: {barcode}</p>}
      {isCameraActive && <button onClick={() => setIsCameraActive(false)}>Turn Off Camera</button>}
    </div>
  );
};

export default BarcodeScanner;
