import React, { useState, useEffect } from 'react';
import ProductTable from './ProductTable';
import PriceIncreaseFilter from './PriceIncreaseFilter';

const ProductDataVisualization = ({ data }) => {
  const [showIncreasesOnly, setShowIncreasesOnly] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (showIncreasesOnly) {
      // Filter to show only items with any price increase
      const increasedPriceData = data.filter(item => {
        return item.cost_price.some((price, index) => {
          return index > 0 && parseFloat(price) > parseFloat(item.cost_price[index - 1]);
        });
      });
      setFilteredData(increasedPriceData);
    } else {
      // Show all items
      setFilteredData(data);
    }
  }, [showIncreasesOnly, data]); // Effect runs when showIncreasesOnly or data changes

  const togglePriceIncreaseFilter = () => {
    setShowIncreasesOnly(!showIncreasesOnly);
  };

  return (
    <div>
      <PriceIncreaseFilter onClick={togglePriceIncreaseFilter} />
      <ProductTable data={filteredData} />
    </div>
  );
};

export default ProductDataVisualization;
