import React, { useState, useContext, useEffect} from 'react';
import ImageUploadAndCrop from './ImageUploadAndCrop.js';
import Tesseract from 'tesseract.js';
import CanadianCashCounter from './CanadianCashCounter.js';
import PhotoToText from './PhotoToText.js';
import BarcodeScanner from './BarcodeScanner.js';
import ReceiptScanner from './ReceiptScannerComponent.js';

const processImage = (image) => {
    Tesseract.recognize(
        image, 
        'eng', // or the language of the text
        {
            logger: m => console.log(m) // logs progress
        }
    ).then(({ data: { text } }) => {
        console.log(text); // extracted text
        // Handle the extracted text as needed
    });
};


const EndOfShiftComponent = (props) => {
    const [total, setTotal] = useState(0);
    const [instant_lottery_total, setInstantLotteryTotal] = useState(0);
    const [online_lottery_total, setOnlineLotteryTotal] = useState(0);
    const [lottery_payout_total, setLotteryPayoutTotal] = useState(0);
    const [cash_receipts_total, setCashReceiptsTotal] = useState(0);
    const handleImageCapture = (image) => {
        processImage(image);
    };
    useEffect(() => {
        if (props.sales && typeof props.sales === 'object') {
            const foodValue = parseFloat(props.sales.food_value) || 0;
            const retailValue = parseFloat(props.sales.retail_value) || 0;
            const nonTaxableValue = parseFloat(props.sales.non_taxable_value) || 0;
            const giftCardValue = parseFloat(props.sales.gift_card_value) || 0;
            const nonTaxableServiceValue = parseFloat(props.sales.non_taxable_service_value) || 0;
            const vendorPayoutValue = parseFloat(props.sales.vendor_payout) || 0;
           
            const calculatedTotal = foodValue + retailValue + nonTaxableValue - giftCardValue;
            setTotal(calculatedTotal.toFixed(2));
            setInstantLotteryTotal(parseFloat(props.sales.instant_lottery_value));
            setOnlineLotteryTotal(parseFloat(props.sales.online_lottery_value));
            setLotteryPayoutTotal(parseFloat(props.sales.lottery_payout_value));
            setCashReceiptsTotal(parseFloat(props.sales.cash_receipts_value)-nonTaxableServiceValue-vendorPayoutValue);
        }
    }, [props.sales]); // Only re-run the effect if props.values changes

    return (
        <div>
            <p>{props.message}</p>
            <p>Food + Retail + Non-Taxable - Gift Cards = {total}</p>
            <p>Instant Lottery = {instant_lottery_total}</p>
            <p>Online Lottery = {online_lottery_total}</p>
            <p>Lottery Payout = {lottery_payout_total}</p>
            <p>Cash = {cash_receipts_total}</p>
            <CanadianCashCounter />

            <PhotoToText />
            {/* <ReceiptScanner /> */}
            {/* <BarcodeScanner/> */}

        </div>
    );
};

export default EndOfShiftComponent;