// ProfitCalcComponent.js
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap'; // Make sure to import necessary elements from react-bootstrap
import DynamicTable from '../Common/DynamicTable'; // Adjust the import path according to your project structure
import { BACKEND_URL } from '../config';
import axios from 'axios';

const ProfitCalcComponent = ({ isUnArchived, date_from, date_to, handleCheckboxChange, handleProfitDateChange, handleGetProfitCalc, profitData, profitHighlightRule }) => {
    const onSaveProfitData = (updatedRowData) => {
        axios.post(`${BACKEND_URL}/run-save_profit_data/`, updatedRowData)
        .then(response => {
            // Handle successful save
            console.log('Row saved successfully:', response.data);
        })
        .catch(error => {
            // Handle error
            console.error('Error saving row:', error);
        });
    };

    return (
    <div>
        <Form.Group controlId="formBasicCheckbox">
            <Form.Check 
                type="checkbox" 
                label="Today's Open Sales" 
                checked={isUnArchived} 
                onChange={handleCheckboxChange} 
            />
        </Form.Group>
        <InputGroup className="mb-3">
            <Form.Control 
                type="date" 
                value={date_from} 
                onChange={handleProfitDateChange('startDate')} 
                disabled={isUnArchived} 
            />
            <Form.Control 
                type="date" 
                value={date_to} 
                onChange={handleProfitDateChange('endDate')} 
                disabled={isUnArchived} 
            />
        </InputGroup>
        <button className='btn btn-dark' onClick={handleGetProfitCalc}>Refresh</button>
        <DynamicTable data={profitData} universalStyleRule={profitHighlightRule} onSaveRow={onSaveProfitData}/>
    </div>
  );
};

export default ProfitCalcComponent;
