import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../config';
import './LoginDetailsForm.css'; // Make sure to create this CSS file

const LoginDetailsForm = () => {
  const [loginDetails, setLoginDetails] = useState({
    Visualtouch: { username: '', password: '' },
    AgileLogistics: { username: '', password: '' },
    InsAnalytics: { username: '', password: '' },
    Gmail: { username: '', password: '' },
  });

  useEffect(() => {
    const fetchLoginDetails = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/getLoginDetails`);
        setLoginDetails(response.data);
      } catch (error) {
        console.error('Error fetching login details:', error);
      }
    };

    fetchLoginDetails();
  }, []);

  const handleChange = (category, field, event) => {
    setLoginDetails((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [field]: event.target.value,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${BACKEND_URL}/updateLoginDetails/`, loginDetails);
      alert('Login details updated successfully!');
    } catch (error) {
      console.error('Error updating login details:', error);
    }
  };

  return (
    <div className="login-details-form">
      <form onSubmit={handleSubmit}>
        {Object.keys(loginDetails).map((key) => (
          <div key={key} className="login-detail-section">
            <h2>{key.replace(/([A-Z])/g, ' $1').trim()} Login Details:</h2>
            <div className="input-group">
              <label htmlFor={`${key}-username`}>Username:</label>
              <input
                id={`${key}-username`}
                type="text"
                value={loginDetails[key].username}
                onChange={(e) => handleChange(key, 'username', e)}
                placeholder="Username"
              />
            </div>
            <div className="input-group">
              <label htmlFor={`${key}-password`}>Password:</label>
              <input
                id={`${key}-password`}
                type="password"
                value={loginDetails[key].password}
                onChange={(e) => handleChange(key, 'password', e)}
                placeholder="Password"
              />
            </div>
          </div>
        ))}
        <button type="submit" className="save-btn">Save Login Details</button>
      </form>
    </div>
  );
};

export default LoginDetailsForm;
