import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './CustomGrid.css'; // Import your custom styles

const CustomCellRenderer = ({ data, context }) => {
  return <button onClick={() => context.onDelete(data.upc)}>Delete</button>;
};


// CustomHeaderComponent adjusted to handle props from AG Grid
const CustomHeaderComponent = (props) => {
  // Implement your custom header logic here
  const { displayName, context } = props;
  const { enableToggle, toggleSNo, isToggled } = context;

  return (
    <div>
        <span>S.No.</span>
    </div>
  );
};


// supported column names:
// abc_num ---> Abc
//              used to specify that the column will be sortable as numbers
// abc_editable ---> Abc
//                   used to specify that the column is editable and will trigger 'onSaveRow'
// abc_num_editable ---> Abc
//                       both _num and _editable
// abcDef ---> Abc Def

// abc_admin ---> Abc
//                only visible if user is passed to the class and user.role == admin
//                this can be 'abc_num_admin', 'abc_editable_admin' or 'abc_num_editable_admin'
function DynamicTable({ data, onSaveRow, user, allowDeleteRow, onDeleteRow, allCapsColumns, columnStyleRules, universalStyleRule }) {
  const [gridApi, setGridApi] = useState(null);
  const [toggleSNo, setToggleSNo] = useState(true); // State to control S.No. toggle

  // Toggle function for S.No.
  const toggleSerialNo = () => setToggleSNo(!toggleSNo);


  const isColumnVisible = (columnName) => {
    if (!user || (user && user.role === 'admin')) {
      return true;
    } else {
      return !columnName.endsWith("_admin");
    }
  };

  const saveUpdatedRow = (event) => {
    if (onSaveRow) {
      onSaveRow(event.data);
    } else {
      console.log('Updated row:', event.data);
    }
  };

  const deleteRow = (data) => {
    if (onDeleteRow) {
      onDeleteRow(data);
    } else {
      console.log('Delete row:', data);
    }
  };

  const isNumericColumn = (key) => {
    return key.includes("_num");
  };

  const makeUpperCase = (params) => {
    return params.value ? params.value.toUpperCase() : '';
  };

  const columnDefs = React.useMemo(() => {
    let cols = [
      {
        headerName: "S.No.",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 90,
        sortable: true,
        // Specify the custom header component
        headerComponent: CustomHeaderComponent,
        // Additional properties for your custom header component can be specified through headerComponentParams
        headerComponentParams: {
          displayName: "S.No.",
          enableToggle: true,
          toggleSNo: toggleSerialNo,
          isToggled: toggleSNo
        },
      },
      // Rest of your columns...
    ];


    if (Array.isArray(data) && data.length > 0) {
      cols = cols.concat(Object.keys(data[0]).map(key => {
        const isEditable = key.endsWith("_editable") || (key.endsWith("_admin") && key.includes("_editable"));
        const isNumeric = isNumericColumn(key);
        const isHidden = key.endsWith("_hidden") || !isColumnVisible(key);
        // Clean up the display name
        let displayName = key
          .replace(/_num_/g, '_')
          .replace(/_num$/, '')
          .replace(/_editable$/, '')
          .replace(/_editable_admin/, '')
          .replace(/_admin$/, '')
          .replace(/_hidden$/, '') // Remove '_hidden' from the display name if present
          .split('_') // Split by underscore
          .map(part => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize first letter of each part
          .join(' '); // Join them back with a space

        let colDef = {
          headerName: displayName,
          field: key,
          sortable: true,
          filter: true,
          editable: isEditable,
          hide: isHidden,
          headerClass: 'header-left-align',
          cellStyle: (params) => {
            // Check for a universal style rule
            if (universalStyleRule && universalStyleRule.field && universalStyleRule.styleFunction) {
              // Apply the style based on the specified field's value
              return universalStyleRule.styleFunction(params.data[universalStyleRule.field], params.data);
            }
            // Default or no additional style
            return null;
          },
          cellRenderer: (params) => {
            // If this is an all caps column, transform the value
            if (allCapsColumns && allCapsColumns.includes(key) && params.value) {
              return params.value.toUpperCase();
            }
            return params.value;
          }
        };
        if (isNumeric) {
          colDef.type = 'numberColumn';
          // Ensure values are treated as numbers for sorting and filtering
          colDef.valueGetter = function(params) {
            if (params.data[key] === "NOT_FOUND") {
              return params.data[key];
            }
            return Number(params.data[key]);
          };
        }

        if (allCapsColumns && allCapsColumns.includes(key)) {
          colDef.onCellValueChanged = (params) => {
            params.data[key] = params.newValue.toUpperCase();
            saveUpdatedRow(params);
          };
        }

        return colDef;
      }));
    }

    // Add the delete button column
    if (allowDeleteRow) {
      cols.push({
        headerName: 'Delete',
        field: "delete",
        maxWidth: 90,
        cellRenderer: CustomCellRenderer,
      });
    }

    return cols;
  }, [data, allCapsColumns, universalStyleRule, toggleSerialNo, toggleSNo]); // Add toggleSNo as a dependency

   // Ensure your custom header component is registered correctly with AG Grid
   const frameworkComponents = {
    customCellRenderer: CustomCellRenderer, // Assuming CustomCellRenderer is already defined
    customHeaderComponent: CustomHeaderComponent, // This is your custom header component
  };
  

  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    cellStyle: { textAlign: 'left' },
  }), []);

  useEffect(() => {
    if (gridApi && data.length) {
      const timeoutId = setTimeout(() => {
        gridApi.autoSizeAllColumns();
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [data, gridApi]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.autoSizeAllColumns();
  };

  const onFirstDataRendered = (params) => {
    params.api.autoSizeAllColumns();
  };

  const gridOptions = useMemo(() => ({
    columnTypes: {
      numberColumn: {
        filter: 'agNumberColumnFilter',
      },
    },
    suppressMovableColumns: true,
    getRowStyle: () => ({ 'userSelect': 'text' }),
    sideBar: {
      toolPanels: [{
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      }],
      defaultToolPanel: 'filters',
    },
    multiSortKey: "ctrl",
  }), []);

  return (
    <div className="ag-theme-alpine custom-grid-class" style={{ height: 'auto', width: '100%', overflowX: 'auto' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={data}
        onGridReady={onGridReady}
        domLayout='autoHeight'
        onCellEditingStopped={saveUpdatedRow}
        onFirstDataRendered={onFirstDataRendered}
        frameworkComponents={frameworkComponents}
        context={{ onDelete: deleteRow, toggleSNo: toggleSerialNo, isToggled: toggleSNo }} // Pass necessary context
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
      />
    </div>
  );
}

export default DynamicTable;
