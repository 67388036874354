// BarcodeScannerInput.js
import React, { useState } from 'react';
import BarcodeScannerModal from './BarcodeScannerModal';
import DynamicTable from '../Common/DynamicTable';

const BarcodeScannerInput = ({ user, onSave}) => {
  const [scannedItems, setScannedItems] = useState([{upc:"01231", description_editable:"ABC"}]);
  const [isScannerActive, setIsScannerActive] = useState(false);

  const handleDetected = (upc) => {
    const newItem = { upc, description_editable: '' }; // Note the "_editable" suffix for the description
    setScannedItems(scannedItems => [...scannedItems, newItem]);
    setIsScannerActive(false); // Turn off the scanner after a barcode is detected
  };

  const handleSaveRow = (updatedRow) => {
    // Update the state with the new row data
    const updatedItems = scannedItems.map(item => 
      item.upc === updatedRow.upc ? updatedRow : item
    );
    setScannedItems(updatedItems);
  };

  // Handler to delete a row based on the UPC
  const handleDeleteRow = (upcToDelete) => {
    setScannedItems(currentItems => currentItems.filter(item => item.upc !== upcToDelete));
  };

  const handleSaveData = () => {
    if (onSave) {
      onSave(scannedItems);
    }
  };

  // Define the column structure expected by DynamicTable
  const columns = [
    { headerName: "UPC", field: "upc", sortable: true, filter: true },
    { headerName: "Description", field: "description_editable", editable: true }
  ];

  return (
    <div>
      <button onClick={() => setIsScannerActive(true)}>Scan Next Item</button>
      <br/><br/>
      <button onClick={handleSaveData}>Save</button>

      {isScannerActive && <BarcodeScannerModal onClose={() => setIsScannerActive(false)} onDetected={handleDetected} />}
      <DynamicTable 
        data={scannedItems} 
        onSaveRow={handleSaveRow} 
        user={user}
        onDeleteRow={handleDeleteRow}
        allowDeleteRow={true}
        allCapsColumns={['description_editable']}
        // You can also pass other necessary props for DynamicTable if needed
      />
    </div>
  );
};

export default BarcodeScannerInput;
