import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import Tesseract from 'tesseract.js';
import './ImageUploadAndCrop.js';

const ImageModal = ({ src, isOpen, onClose, onCropComplete }) => {
    const [crop, setCrop] = useState({
        unit: '%',
        x: 0,
        y: 0,
        width: 100, // Increase the initial width
        height: 100, // Increase the initial height
        aspect: 1 / 1  // Adjust the aspect ratio as needed
    });
    
    if (!isOpen) {
        return null;
    }
    const handleImageLoaded = (image) => {
        // Example: Adjust the crop area based on the image size
        setCrop({
            ...crop,
            aspect: image.width / image.height,
            width: 100, // or any other initial width in percentage
            height: 100, // or any other initial width in percentage
            x: 0,
            y: 0,
        });
    };
    
    const handleCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    const handleCropComplete = (crop) => {
        // Create cropped image here and pass it to onCropComplete
        // ...
    };

    return (
        <div className="modal" style={{ display: 'block' }}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                {src && (
                    <ReactCrop
                    src={src}
                    onImageLoaded={handleImageLoaded}
                    style={{ width: '100%', height: '100%' }} // Adjust style as needed
                />
                
                )}

            </div>
        </div>
    );
};


const ImageUploadAndCrop = () => {
    const [src, setSrc] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSrc(reader.result);
                setIsModalOpen(true); // Assuming you're using a modal
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCropComplete = (croppedImage) => {
        setCroppedImageUrl(croppedImage);
        setIsModalOpen(false);
    };

    return (
        <div>
            <input type="file" accept="image/*" capture="environment" onChange={handleFileChange} />
            <ImageModal
                src={src}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onCropComplete={handleCropComplete}
            />
            {croppedImageUrl && <img src={croppedImageUrl} alt="Cropped" />}
            {/* ... rest of the component ... */}
        </div>
    );
};


export default ImageUploadAndCrop;
