import React, { useState } from 'react';
import Tesseract from 'tesseract.js';

const PhotoToText = () => {
    const [image, setImage] = useState('');
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    };
    const preprocessImage = (imageSrc, callback) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const image = new Image();
    
        image.onload = () => {
            // Define your desired aspect ratio
            const desiredAspectRatio = 2 / 3; // For a vertical rectangle
    
            // Calculate the crop dimensions
            let newWidth = image.width;
            let newHeight = image.height;
            const imageAspectRatio = image.width / image.height;
    
            if (imageAspectRatio > desiredAspectRatio) {
                // Image is wider than the desired aspect ratio; we need to crop width
                newWidth = image.height * desiredAspectRatio;
            } else if (imageAspectRatio < desiredAspectRatio) {
                // Image is taller than the desired aspect ratio; we need to crop height
                newHeight = image.width / desiredAspectRatio;
            }
    
            const offsetX = (image.width - newWidth) / 2;
            const offsetY = (image.height - newHeight) / 2;
    
            canvas.width = newWidth;
            canvas.height = newHeight;
    
            // Draw the cropped image
            context.drawImage(image, offsetX, offsetY, newWidth, newHeight, 0, 0, newWidth, newHeight);
    
            // Convert to grayscale
            let imageData = context.getImageData(0, 0, newWidth, newHeight);
    
            for (let i = 0; i < imageData.data.length; i += 4) {
                const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
                imageData.data[i] = avg; // Red
                imageData.data[i + 1] = avg; // Green
                imageData.data[i + 2] = avg; // Blue
            }
    
            // Apply additional preprocessing steps here
            // Binarization
            for (let i = 0; i < imageData.data.length; i += 4) {
                const threshold = 128; // Adjust this threshold as needed
                const brightness = 0.34 * imageData.data[i] + 0.5 * imageData.data[i + 1] + 0.16 * imageData.data[i + 2];
                const binaryColor = brightness < threshold ? 0 : 255;
                imageData.data[i] = binaryColor; // Red
                imageData.data[i + 1] = binaryColor; // Green
                imageData.data[i + 2] = binaryColor; // Blue
            }
    
            // Noise reduction (optional, depending on your image quality)
            // Simple methods might not be effective for all types of noise,
            // so consider more advanced techniques if necessary.
    
            // Contrast adjustment
            // Adjusting contrast can be complex; consider libraries like CamanJS for more advanced image processing.
    
            context.putImageData(imageData, 0, 0);
    
            callback(canvas.toDataURL());
        };
    
        image.src = imageSrc;
    };
    

    const convertImageToText = () => {
        setIsLoading(true);
        setProgress(0);

        preprocessImage(image, (processedImage) => {
            Tesseract.recognize(
                processedImage,
                'eng',
                {
                    logger: m => {
                        console.log(m);
                        if (m.status === 'recognizing text') {
                            setProgress(Math.floor(m.progress * 100));
                        }
                    }
                }
            ).then(({ data: { text } }) => {
                setText(text);
                setIsLoading(false);
            }).catch(error => {
                console.error('OCR Error:', error);
                setText('Failed to extract text. Please try again.');
                setIsLoading(false);
            });
        });
    };

    return (
        <div>
            <input type="file" accept="image/*" capture="environment" onChange={handleImageChange} />
            <button onClick={convertImageToText} disabled={!image || isLoading}>
                Convert to Text
            </button>
            {isLoading && <p>Loading... {progress}%</p>}
            <p>Extracted Text: {text}</p>
        </div>
    );
};

export default PhotoToText;
