import React, { useEffect, useState, useMemo } from 'react';
import DynamicTable from '../Common/DynamicTable'; // Import your DynamicTable component
import {BACKEND_URL} from '../config'; // Update this path to your configuration file
import axios from 'axios';

const VerifyOrderComponent = ({ user, orderId, initialOrderData, setMessage }) => {
  // State to hold the original data
  const [orderData, setOrderData] = useState([]);

  // State to hold the search query
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setOrderData(initialOrderData.map(({ upc, name, quantity }) => ({
      upc,
      name,
      qtyInvoiced_num: quantity, // Renaming quantity to qtyInvoiced
      qtyReceived_num_editable: 0 // Adding a new column qtyReceived with default value 0
    })));
  }, [initialOrderData, orderId]);

  // useMemo to filter data based on search query
  const filteredData = useMemo(() => {
    if (!searchQuery) return orderData; // If no search query, return original data
    return orderData.filter(item => 
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
      item.upc.toLowerCase().includes(searchQuery.toLowerCase()) // Matching search query with upc as well
    );
  }, [searchQuery, orderData]);

  const handleQuantityChange = (updatedRowData) => {
    // itemNumber, newQuantity
    setOrderData(orderData => orderData.map((item) => 
        item.upc === updatedRowData.upc ? { ...item, qtyReceived_num_editable: updatedRowData.qtyReceived_num_editable } : item
    ));
  };

  // Function to clear the search field
  const clearSearch = () => {
    setSearchQuery('');
  };

  const handleSaveButtonClick = () => {
    const jsonData = orderData.map(item => ({
      upc: item.upc,
      name: item.name,
      qtyInvoiced_num: item.qtyInvoiced_num,
      qtyReceived_num_editable: item.qtyReceived_num_editable,
    }));

    axios.post(`${BACKEND_URL}/run-save_verify_invoice_data/`, { data: jsonData, invoiceId: orderId, userEmail: user.email })
      .then(response => {
        console.log('Data saved successfully:', response.data);
        setMessage('Data saved successfully');
      })
      .catch(error => {
        console.error('Error saving data:', error);
        setMessage('Error saving data');
      });
  };

  const handleLoadQuantities = () => {
    axios.get(`${BACKEND_URL}/run-load_verify_invoice_data/${orderId}`)
      .then(response => {
        const quantityData = response.data.verify_invoice_database;
        // Check if quantityData is not an empty string
        if (quantityData !== '') {
          setOrderData(quantityData);
        } else {
          // Optional: take some action if quantityData is empty, e.g., log a message or set a state variable
          console.log('No quantity data to load');
        }
        setMessage(response.data.message);
      })
      .catch(error => {
        console.error('Error fetching quantity data:', error);
        setMessage('Error fetching quantity data');
      });
  };

  return (
    <div>
      {/* Search field with clear button */}
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <input
          type="text"
          placeholder="Search by name or UPC..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginRight: '5px' }}
        />
        <button onClick={clearSearch}>Clear</button>
        <button onClick={handleSaveButtonClick}>Save</button>
        <button onClick={handleLoadQuantities}>Load</button>
      </div>

      {/* Dynamic table */}
      <DynamicTable data={filteredData} onSaveRow={handleQuantityChange} />
    </div>
  );
};

export default VerifyOrderComponent;
