// PlanogramScanner.js
import React from 'react';
import BarcodeScannerInput from '../Reports/BarcodeScannerInput';
import {BACKEND_URL} from '../config';
import axios from 'axios';

const PlanogramScanner = ({user, setMessage}) => {
  const handleSaveScannedItems = (scannedData) => {
    axios.post(`${BACKEND_URL}/run-save_scanned_data/`, scannedData)
      .then(response => {
        // Handle successful save
        console.log('Scanned data saved successfully:', response.data);
        setMessage("Scanned data saved successfully!")
      })
      .catch(error => {
        // Handle error
        console.error('Error saving scanned data:', error);
      });
  };
  return (
    <div>
      <h2>Scan Items that don't scan in Visualtouch</h2>
      <BarcodeScannerInput user={user} onSave={handleSaveScannedItems} />
    </div>
  );
};

export default PlanogramScanner;
