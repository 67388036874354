import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CanadianCashCounter = () => {
    const denominations = {
        '$0.05': 0.05,
        '$0.10': 0.10,
        '$0.25': 0.25,
        '$1': 1.00,
        '$2': 2.00,
        '$5': 5.00,
        '$10': 10.00,
        '$20': 20.00,
        '$50': 50.00,
        '$100': 100.00
    };

    const initialCounts = Object.keys(denominations).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
    }, {});

    const [counts, setCounts] = useState(initialCounts);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const changeHandler = () => {
            // Check if the document is currently in fullscreen mode
            setIsFullScreen(!!document.fullscreenElement);
        };

        // Listen for fullscreen change events (for entering and exiting fullscreen mode)
        document.addEventListener('fullscreenchange', changeHandler);

        return () => {
            document.removeEventListener('fullscreenchange', changeHandler);
        };
    }, []);

    const handleInputChange = (denomination, value) => {
        setCounts({
            ...counts,
            [denomination]: parseInt(value, 10) || 0
        });
    };

    const calculateTotal = () => {
        return Object.keys(counts).reduce((total, key) => {
            return total + denominations[key] * counts[key];
        }, 0);
    };

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            containerRef.current.requestFullscreen().catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const denominationKeys = Object.keys(denominations).reverse();

    return (
        <div className="container mt-4" ref={containerRef}>
            {/* {!isFullScreen && (
                <button className="btn btn-primary mb-3" onClick={toggleFullScreen}>
                    Enter Full Screen
                </button>
            )} */}
            <h2 className="mb-3">Cash Counter             </h2>
            <div className="row">
                {denominationKeys.map((denomination) => (
                    <div className="col-md-4 mb-3" key={denomination}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" style={{ minWidth: "80px" }}>
                                    {denomination}
                                </span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                value={counts[denomination]}
                                onChange={(e) => handleInputChange(denomination, e.target.value)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col">
                    <h3>Total: ${calculateTotal().toFixed(2)}</h3>
                </div>
            </div>
        </div>
    );
};

export default CanadianCashCounter;
