export function createStyleRule({
    field,
    thresholds,
    colors = [
        { background: 'red', text: 'white' }, // Style for values < 0
        { background: 'orange', text: 'black' } // Style for values < 40
    ],
    textAlign = 'left' }) {
    return {
        field, // The field name to use for styling decisions
        styleFunction: (value) => { // The function to determine the style
            let style = { textAlign }; // Default text alignment

            // Determine the background color and text color based on thresholds
            if (value < thresholds[0]) {
                style.backgroundColor = colors[0].background;
                style.color = colors[0].text;
            } else if (value < thresholds[1]) {
                style.backgroundColor = colors[1].background;
                style.color = colors[1].text;
            } else {
                style.backgroundColor = 'transparent';
                style.color = 'black';
            }

            return style;
        }
    };
}