import React, { useState, useEffect, useMemo } from 'react';
import { Collapse, Tabs, Tab, Button, Table } from 'react-bootstrap';
import PdfViewer from '../Pdf/PdfViewer'; // Update this path to your PdfViewer component
import DynamicTable from '../Common/DynamicTable'; // Update this path to your DynamicTable component
import axios from 'axios';
import { BACKEND_URL } from '../config'; // Update this path to your configuration file
import './PlanogramViewer.css'; // Import your custom styles

const PlanogramViewer = ({ user, setMessage }) => {
    const [listItems, setListItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [planogramListOpen, setPlanogramListOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get(`${BACKEND_URL}/run-get_planogram_database/`)
            .then(response => {
                // Assuming response.data is the array of arrays from your JSON
                const firstData = response.data.planogram_database;
                const secondData = response.data.selling_price_database;
                const thirdData = response.data.cost_price_agile_database;
                // Process and merge data
                const mergedData = firstData.map(firstRow => {
                    const itemNumber = firstRow[2]; // Assuming item number is at index 2
                    const upcNumber = firstRow[1]; // Assuming upc number is at index 1
                    const secondRow = secondData.find(row => (
                        row[0] === itemNumber ||
                        itemNumber.replace(/^T/, '').replace(/^0+/, '') === row[0].replace(/^0+/, '')
                    ));
                    const sellingPrice = secondRow ? secondRow[secondRow.length - 1] : "NOT_FOUND";
                    const thirdRow = thirdData.find(row => row[1] === upcNumber);
                    const costPrice = thirdRow ? thirdRow[thirdRow.length - 1] : "NOT_FOUND";
                    let markupValue = "--"
                    if (sellingPrice !== "NOT_FOUND" && costPrice !== "NOT_FOUND") {
                        markupValue = Math.round((parseFloat(sellingPrice) - parseFloat(costPrice)) / parseFloat(costPrice) * 100 * 100) / 100;
                    }
                    return {
                        rowData_hidden: firstRow.slice(0),
                        upc: upcNumber,
                        itemNumber: itemNumber,
                        description: firstRow[3],
                        costPrice_num_admin: costPrice,
                        sellingPrice_num: sellingPrice,
                        markup_num_admin: markupValue,
                        quantity_editable: "0"
                    };
                });
                console.log(response.data.planogram_database);  // Log to inspect the structure

                // Extracting list items (file names)
                const fileList = Array.from(new Set(response.data.planogram_database.map(item => item[0])));

                // Setting state
                setListItems(fileList);
                setTableData(mergedData);
            })
            .catch(error => {
                console.error('Error fetching planogram data:', error);
            });
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredTableData = useMemo(() => {
        let displayData = tableData.filter(row =>
            row.description.toLowerCase().includes(searchTerm)
        );
        return displayData.filter(row =>
            !selectedItem || row.rowData_hidden[0] == selectedItem
        );
    }, [tableData, searchTerm, selectedItem]); // Remove filteredTableData from dependencies

    const handleListItemClick = (itemName) => {
        setSelectedItem(itemName);
    };

    const handlePlanogramListToggle = () => {
        setPlanogramListOpen(!planogramListOpen);
    };

    const handleQuantityChange = (updatedRowData) => {
        // itemNumber, newQuantity
        setTableData(prevData => prevData.map((item) =>
            item.itemNumber === updatedRowData.itemNumber ? { ...item, quantity_editable: updatedRowData.quantity_editable } : item
        ));
    };

    const handleSaveButtonClick = () => {
        const jsonData = filteredTableData.map(item => ({
            upc: item.rowData_hidden[1], // Ensure this matches your data structure
            description: item.description,
            quantity: item.quantity_editable,
            selling_price: item.sellingPrice_num // Ensure this matches your data structure
        }));

        axios.post(`${BACKEND_URL}/run-save_quantity_data/`, { data: jsonData, userEmail: user.email })
            .then(response => {
                console.log('Data saved successfully:', response.data);
            })
            .catch(error => {
                console.error('Error saving data:', error);
            });
    };


    const handleLoadQuantities = () => {
        axios.get(`${BACKEND_URL}/run-get_quantity_database/`)
            .then(response => {
                // Assuming the response data is an array of objects with itemNumber and quantity
                const quantityData = response.data.quantity_database; // Adjust this based on your actual response structure

                // Create a mapping from upc to quantity for easier lookup
                const quantityMap = new Map(quantityData.map(item => [item.upc, item.quantity]));

                // Update tableData with the quantities from the response
                const updatedTableData = tableData.map(item => {
                    // If the upc exists in the quantityMap, update the quantity_editable
                    if (quantityMap.has(item.upc)) {
                        return { ...item, quantity_editable: quantityMap.get(item.upc) };
                    }
                    // If not found, return the item unchanged
                    return item;
                });

                // Update the state with the new table data
                setTableData(updatedTableData);
                setMessage(response.data.message);
            })
            .catch(error => {
                console.error('Error fetching quantity data:', error);
            });
    };

    return (
        <div>
            <div className="search-section">
                <input
                    type="text"
                    placeholder="Search in Description..."
                    onChange={handleSearchChange}
                    value={searchTerm}
                />
            </div>
            <div className="viewer-layout">
                <Button
                    onClick={handlePlanogramListToggle}
                    className={`toggle-list-button ${planogramListOpen ? 'open' : ''}`}
                    aria-controls="collapsible-list"
                    aria-expanded={planogramListOpen}
                >
                    {planogramListOpen ? '←' : '→'} {/* Change these arrows as needed */}
                </Button>

                <Collapse in={planogramListOpen}>
                    <div id="collapsible-list">
                        <div className='list-section'>
                            {listItems.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleListItemClick(item)}
                                    className={`list-item ${item === selectedItem ? 'selected' : ''}`}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </Collapse>
                <Table striped bordered hover>
                    <Tabs defaultActiveKey="tableData" id="planogram-tabs">
                        <Tab eventKey="pdfViewer" title="View PDF">
                            {selectedItem && <PdfViewer file={`${BACKEND_URL}/run-view_pdf/${selectedItem}`} />}
                        </Tab>
                        <Tab eventKey="tableData" title="Data Table">
                            <Button onClick={handleSaveButtonClick} className="mt-3">
                                Save Changes
                            </Button>
                            <Button onClick={handleLoadQuantities} className="mt-3">
                                Load Existing Quantity
                            </Button>
                            <DynamicTable
                                data={filteredTableData}
                                onSaveRow={handleQuantityChange}
                                user={user}
                            />
                        </Tab>
                    </Tabs>
                </Table>
            </div>
        </div>
    );
};

export default PlanogramViewer;
