// src/Menu.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BACKEND_URL} from './config.js';
import './Menu.css';
import UserContext from './UserContext';
import EndOfShiftComponent from './Reports/Reports.js';
import VerifyOrderComponent from './Orders/VerifyOrderComponent.js';
import AnalyzeOrderComponent from './Orders/AnalyzeOrderComponent.js';
import { Form, InputGroup } from 'react-bootstrap';
import moment from 'moment-timezone';

import PlanogramViewer from './Planogram/PlanogramViewer';
import PlanogramScanner from './Planogram/PlanogramScanner';
import ProductDataVisualization from './Stats/CostPriceComponents/ProductDataVisualization';
import DynamicTable from './Common/DynamicTable';
import ProfitCalcComponent from './Reports/ProfitCalcComponent';
import InstantTicketsComponent from './components/InstantTicketsComponent';
import CustomNavbar from './components/CustomNavBar';
import { createStyleRule } from './Common/Utils';
import LoginDetailsForm from './components/LoginDetailsForm.js';

const universalStyleRule = createStyleRule({
    field: 'markup',
    thresholds: [0, 40] // Thresholds for changing styles
  });
  
  const profitHighlightRule = createStyleRule({
    field: 'profitPercent_num',
    thresholds: [0, 28.57], // Thresholds for changing styles
  });

const Menu = () => {
    const timezone = "America/New_York"; // e.g., 'America/New_York'
    const todayInTimezone = moment.tz(timezone).format('YYYY-MM-DD');
    const [message, setMessage] = useState('');
    const [invoiceOrders, setInvoiceOrders] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [invoiceOrderId, setInvoiceOrderId] = useState('');
    const [activeComponent, setActiveComponent] = useState(); // Set default component
    const [ticketData, setTicketData] = useState([]);
    const { user } = useContext(UserContext);
    const [salesData, setSalesData] = useState('');
    const [salesMessage, setSalesMessage] = useState('');
    const [isUnArchived, setIsUnArchivedChecked] = useState(true); // default checkbox state
    const [date_from, setFromDate] = useState(todayInTimezone); // YYYY-MM-DD format
    const [date_to, setToDate] = useState(todayInTimezone);
    // State for controlling Navbar toggle
    const [navExpanded, setNavExpanded] = useState(false);
    const [updatedSPData, setUpdatedSPData] = useState([]);
    const [costPriceData, setCostPriceData] = useState([]);
    const [missingAgileItemsData, setMissingAgileItemsData] = useState([]);
    const [updatedSellingPriceData, setUpdatedSellingPriceData] = useState([]);
    const [dropShippedItemsData, setDropShippedItemsData] = useState([]);
    const [mismatchedItemsData, setMismatchedItemsData] = useState([]);
    const [notDeliveredItemsData, setNotDeliveredItemsData] = useState([]);
    const [profitData, setProfitData] = useState([]);
    const [dynamicTableData, setDynamicTableData] = useState([]);
    const [totalProfit, setTotalProfit] = useState('0.0');
    const [needsReAuth, setNeedsReAuth] = useState(false); // Default to false to start with the list collapsed
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    
    
    // Handler for Navbar toggle
    const toggleNavbar = () => {
        setNavExpanded(prev => !prev);
    };

   // Handler for menu item click
   const handleNavSelect = (eventKey) => {
        // Collapse the navbar
        setNavExpanded(false);
        setMessage("");

        // Handle specific actions based on the menu item clicked
        switch(eventKey) {
        case 'config':
            handleConfigClick();
            break;
        case 'viewer':
            handleViewerClick();
            break;
        case 'scanner':
            handleScannerClick();
            break;
        case 'get-database':
            handleGetLatestPlanogramClick();
            break;
        case 'get-price-list':
            handleGetLatestSellingPriceClick()
            break;
        case 'get-updated-invoices':
            handleRefreshInvoices();
            break;
        case 'analyze-order':
            handleAnalyzeOrderClick();
            break;
        case 'verify-order':
            handleVerifyOrderClick();
            break;
        case 'add-missing-items':
            handleAddMissingItemsCart();
            break;
        case 'instant_tickets':
            handleInstantTickets();
            break;
        case 'end_of_shift':
            handleEndOfShift();
            break;
        case 'profit_calc':
            handleProfitCalc();
            break;
        case 'cost-price-db':
            handleGetCostPriceDb();
            break;
        case 'missing-items-agile':
            handleGetMissingItemsAgile();
            break;
        case 'selling-price-db':
            handleGetSellingPriceDb();
            break;
        case 'updated-selling-price-db':
            handleGetUpdatedSellingPriceDb();
        default:
            break;
        }
    };

    const handleGetEndOfShift = (itemName) => {
        setSalesMessage("Processing...");
        console.info(`${BACKEND_URL}/run-get_end_of_shift/${isUnArchived}/${date_from}/${date_to}`)
        axios.get(`${BACKEND_URL}/run-get_end_of_shift/${isUnArchived}/${date_from}/${date_to}`)
        .then(response => {
            setSalesData(response.data);
            setSalesMessage("Done");
        })
        .catch(error => {
            console.error('Error fetching order details:', error);
            setSalesMessage("Error fetching order details:", error);
        });
    };
    const handleGetProfitCalc = (itemName) => {
        setMessage("Processing...");
        console.info(`${BACKEND_URL}/run-get_profit/${isUnArchived}/${date_from}/${date_to}`)
        axios.get(`${BACKEND_URL}/run-get_profit/${isUnArchived}/${date_from}/${date_to}`)
        .then(response => {
            const sales_database = response.data;
            let total_profit = parseFloat("0.0");
            const profitDatabase = sales_database.map(firstRow => {
                // const totalCostPrice = firstRow[4] === "NOT_FOUND" ? "NOT_FOUND" : (parseFloat(firstRow[2]) * parseFloat(firstRow[4])).toFixed(2);
                const totalCostPrice = firstRow[5];
                const totalSellingPrice = firstRow[3];
                const profit = totalCostPrice === "NOT_FOUND" ? "NOT_FOUND" : (parseFloat(firstRow[3]) - parseFloat(totalCostPrice));
                const profit_percent = profit === "NOT_FOUND" ? "NOT_FOUND": (parseFloat(profit)/ parseFloat(totalSellingPrice) * 100).toFixed(2);
                total_profit = profit === "NOT_FOUND"? total_profit : (total_profit + parseFloat(profit));
                return {
                    itemNumber: firstRow[0],
                    description: firstRow[1],
                    qtySold_num: firstRow[2],
                    totalSellingPrice_num: firstRow[3],
                    unitCostPrice_num_editable: firstRow[4],
                    totalCostPrice_num: totalCostPrice,
                    profit_num: profit === "NOT_FOUND" ? profit : profit.toFixed(2),
                    profitPercent_num: profit_percent
                };
            });
            setProfitData(profitDatabase);
            setTotalProfit(total_profit === "NOT_FOUND" ? total_profit : total_profit.toFixed(2));
            setMessage(`Total Profit: ${total_profit === "NOT_FOUND" ? total_profit : total_profit.toFixed(2)}`);
        })
        .catch(error => {
            console.error('Error fetching order details:', error);
            setMessage("Error fetching order details:", error);
        });
    };


    const handleCheckboxChange = (e) => {
        setIsUnArchivedChecked(e.target.checked);
    };
    const handleEndOfShift = (itemName) => {
        setActiveComponent('end_of_shift_component');
    };
    const handleProfitCalc = (itemName) => {
        setActiveComponent('profit_calc_component');
        setMessage(`Total Profit: ${totalProfit}`);
    };
    const handleProfitDateChange = (dateType) => (e) => {
        if (dateType === 'startDate') {
            setFromDate(e.target.value); // e.target.value is in YYYY-MM-DD
        } else {
            setToDate(e.target.value); // e.target.value is in YYYY-MM-DD
        }
    };
    
    const handleDateChange = (dateType) => (e) => {
        setFromDate(e.target.value); // e.target.value is in YYYY-MM-DD
        setToDate(e.target.value); // e.target.value is in YYYY-MM-DD
    };
    

    const handleInstantTickets = () => {
        setActiveComponent('instant_tickets');
        axios.get(`${BACKEND_URL}/run-get_instant_ticket_list/`)
            .then(response => {
                if (!response.data || !Array.isArray(response.data)) {
                    console.error('Invalid data format:', response.data);
                    return;
                }
    
                const data = response.data.map(item => ({
                    ...item,
                    unactivated_quantity: item.unactivated_quantity !== undefined ? parseInt(item.unactivated_quantity) : 0,
                    activated_quantity: item.activated_quantity !== undefined ? parseInt(item.activated_quantity) : 0,
                    tickets_in_pack: item.tickets_in_pack !== undefined ? parseInt(item.tickets_in_pack) : 0,
                    current_count: item.current_count !== undefined ? parseInt(item.current_count) : item.activated_quantity*item.tickets_in_pack,
                    starting_count: item.starting_count !== undefined ? parseInt(item.current_count) : item.activated_quantity*item.tickets_in_pack
                }));
    
                setTicketData(data);
            })
            .catch(error => console.error('Error fetching tickets:', error));
    };
    
    

    const handleFetchOrderDetails = (orderNumber) => {
        setActiveComponent('invoice_viewer');
        setMessage("");
        axios.get(`${BACKEND_URL}/run-get_order_details/${orderNumber}/`)
            .then(response => {
                setOrderData(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching order details:', error);
            });
    };

    const handleInstantTicketUnActivatedQuantityChange = (index, newQuantity) => {
        setTicketData(currentData =>
            currentData.map((item, idx) => 
                idx === index ? { ...item, unactivated_quantity: newQuantity } : item
            )
        );
    };

    const handleInstantTicketActivatedQuantityChange = (index, newQuantity) => {
        setTicketData(currentData =>
            currentData.map((item, idx) => 
                idx === index ? { ...item, activated_quantity: newQuantity } : item
            )
        );
    };
    const handleInstantTicketQtyInPackChange = (index, num_tickets_in_pack) => {
        setTicketData(currentData =>
            currentData.map((item, idx) => 
                idx === index ? { ...item, tickets_in_pack: num_tickets_in_pack } : item
            )
        );
    };
    
    const handleInstantTicketCountChange = (index, num_tickets) => {
        setTicketData(currentData =>
            currentData.map((item, idx) => 
                idx === index ? { ...item, current_count: num_tickets } : item
            )
        );
    };
    
    const handleInstantTicketQuantitySave = () => {
        const requestData = {
            userEmail: user.email,  // Include the user email in the request
            ticketData: ticketData  // The existing ticket data
        };
        axios.post(`${BACKEND_URL}/run-save_instant_tickets_quantity/`, requestData)
            .then(response => {
                // Handle response
                console.log('Data saved:', response.data);
            })
            .catch(error => console.error('Error saving data:', error));
    };

    const handleInstantTicketQuantityReset = () => {
        setTicketData(currentData =>
            currentData.map(item => ({
                ...item, 
                activated_quantity: 0, 
                unactivated_quantity: 0
            }))
        );
    };
    

    const handleRefreshInvoices = () => {
        setActiveComponent(null);
        setMessage('Downloading all Agile Invoices from email...');
        axios.get(`${BACKEND_URL}/run-get_all_invoices/`)
            .then(response => {
                setInvoiceOrders(response.data.order_numbers);
                setMessage('Done');
                setNeedsReAuth(false);
            })
            .catch(error => {
                console.error('Error fetching invoices:', error);
                setNeedsReAuth(true);
                setMessage('Google authentication might have expired!');
            });
    };

    const getInvoiceList = () => {
        axios.get(`${BACKEND_URL}/run-get_invoice_list/`)
            .then(response => {
                setInvoiceOrders(response.data.order_numbers);
            })
            .catch(error => {
                console.error('Error getting invoice list:', error);
            });
    };

    
    const handleAnalyzeOrderClick = () => {
        setActiveComponent('analyze-order');
        setMessage("Analyzing the latest order confirmation against latest invoice...");
        axios.get(`${BACKEND_URL}/run-analyze_latest_order/`)
            .then(response => {
                setMessage(response.data.message); // Update the state with the script output
                const drop_shipped_items = response.data.drop_shipped_items;
                const dropShippedDataMap = drop_shipped_items.map(firstRow => {
                    return {
                        item: firstRow[0],
                        quantity: firstRow[1]
                    };
                });
                setDropShippedItemsData(dropShippedDataMap);
                const mismatched_items = response.data.mismatched_items;
                const mismatchedItemsDataMap = mismatched_items.map(firstRow => {
                    return {
                        item: firstRow[0],
                        quantityOrdered: firstRow[1],
                        quantityDelivered: firstRow[2]
                    };
                });
                setMismatchedItemsData(mismatchedItemsDataMap);
                const not_delivered_items = response.data.not_delivered_items;
                const notDeliveredItemsDataMap = not_delivered_items.map(firstRow => {
                    return {
                        item: firstRow[0],
                        quantity: firstRow[1]
                    };
                });
                setNotDeliveredItemsData(notDeliveredItemsDataMap);
                const order_id = response.data.order_id;
                setMessage(`Done, Please review the below tables for Invoice# ${order_id} ...`);
                setNeedsReAuth(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setNeedsReAuth(true);
                setMessage('Google authentication might have expired!');
            });
    };
    const handleVerifyOrderClick = async () => {
        try {
            setActiveComponent('verify-order');
            setMessage("Finding the latest invoice#...");
    
            const latestOrderResponse = await axios.get(`${BACKEND_URL}/run-analyze_latest_order/`);
            const order_id = latestOrderResponse.data.order_id;
            setMessage(`Fetching the data for invoice# ${order_id}`);
            console.log(`Request URL: ${BACKEND_URL}/run-get_order_details/${order_id}/`);
    
            const orderDetailsResponse = await axios.get(`${BACKEND_URL}/run-get_order_details/${order_id}/`);
            setInvoiceOrderId(order_id);
            setOrderData(orderDetailsResponse.data.data);
        } catch (error) {
            console.error('Error during the order verification process:', error);
            setMessage("Failed to fetch order details. Please try again."); // Provide feedback to the user
        }
    };
    

    // And in the handleReAuth function, you can set the authUrl (you might want to move the authUrl construction here or to useEffect if it doesn't change)
    const handleReAuth = (e) => {
        const CLIENT_ID = "17260208687-aifnuem7e9rbs5vtrm7t8euqvdg941of.apps.googleusercontent.com";
        const REDIRECT_URI = `${BACKEND_URL}/google_auth_callback`;
        const SCOPE = "https://www.googleapis.com/auth/gmail.readonly";

        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&access_type=offline&prompt=consent`;

        e.preventDefault(); // Prevent default anchor action
        // window.location.href = authUrl; // Redirect the user for authentication
        const authWindow = window.open(authUrl, "_blank", "width=500,height=600");
        setIsAuthenticating(true); // Indicate that authentication is in progress
    };

    const handleAddMissingItemsCart = () => {
        setActiveComponent(null);
        axios.get(`${BACKEND_URL}/run-add_missing_items_cart/`)
            .then(response => {
                setMessage(response.data.message); // Update the state with the script output
            })
            .catch(error => {
                console.error('Error:', error);
                setMessage('Failed to get script output');
            });
    };

    const handleGetLatestPlanogramClick = () => {
        setActiveComponent(null);
        setMessage('Downloading latest planogram...');
        axios.get(`${BACKEND_URL}/run-get_latest_planogram/`)
            .then(response => {
                setMessage(response.data.message); // Update the state with the script output
            })
            .catch(error => {
                console.error('Error:', error);
                setMessage('Failed to get script output');
            });
    };

    const handleGetCostPriceDb = () => {
        setActiveComponent("cost_price_database");
        setMessage('Getting available cost price list...');
        axios.get(`${BACKEND_URL}/run-get_cost_price/`)
            .then(response => {
                setCostPriceData(response.data.cost_price_database);
                setMessage(response.data.message); // Update the state with the script output
            })
            .catch(error => {
                console.error('Error:', error);
                setMessage('Failed to get script output');
            });
    }

    const handleGetUpdatedSellingPriceDb = () => {
        setActiveComponent("updated_selling_price_database");
        setMessage('Getting Latest Updates on Selling price...');
        axios.get(`${BACKEND_URL}/run-get_updated_selling_price/`)
            .then(response => {
                const updated_selling_price_database = response.data.updated_selling_price_database;
                const prev_selling_price_database = response.data.prev_selling_price_database;
                const upatedSPDataMap = updated_selling_price_database.map(firstRow => {
                    const itemNumber = firstRow[1]; // Assuming item number is at index 1
                    const prev_selling_price_row = prev_selling_price_database.find(row => row[1] === itemNumber);
                    const older_selling_price = prev_selling_price_row ? prev_selling_price_row[prev_selling_price_row.length - 1] : "NOT_FOUND";
                    return {
                        itemNumber: firstRow[0],
                        description: firstRow[1],
                        category: firstRow[2],
                        itemState: firstRow[3],
                        itemType: firstRow[4],
                        sellingPrice: firstRow[5],
                        prevSellingPrice: older_selling_price,
                        state: firstRow[6]

                    };
                });
                setUpdatedSellingPriceData(upatedSPDataMap);
                setMessage(response.data.message); // Update the state with the script output
            })
            .catch(error => {
                console.error('Error:', error);
                setMessage('Failed to get script output');
            });
    }
    const handleGetSellingPriceDb = () => {
        setActiveComponent("dynamic_table_viewer");
        setMessage('Getting Last recorded Selling price database...');
        axios.get(`${BACKEND_URL}/run-get_selling_price/`)
            .then(response => {
                const selling_price_database = response.data.selling_price_database;
                let sellingPriceData = selling_price_database.map(firstRow => {
                    return {
                        itemNumber: firstRow[0],
                        description: firstRow[1],
                        category: firstRow[2],
                        sellingPrice: firstRow[5]
                    };
                });
                sellingPriceData = sellingPriceData.filter(sellingPrice => sellingPrice.category !== "TOBACCO");

                setDynamicTableData(sellingPriceData);
                setMessage(response.data.message); // Update the state with the script output
            })
            .catch(error => {
                console.error('Error:', error);
                setMessage('Failed to get script output');
            });
    }

    const handleGetMissingItemsAgile = () => {
        setActiveComponent("missing_items_agile");
        setMessage('Getting planogram items list missing from agile showroom...');
        axios.get(`${BACKEND_URL}/run-get_missing_items_agile/`)
        .then(response => {
            // Assuming response.data is the array of arrays from your JSON
            const firstData = response.data.planogram_database;
            const secondData = response.data.selling_price_database;
            const listData = response.data.list;

            setMessage(listData);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    const handleGetLatestSellingPriceClick = () => {
        setActiveComponent("updated_selling_price_viewer");
        setMessage('Getting latest selling price list from Visualtouch...');
        axios.get(`${BACKEND_URL}/run-get_latest_selling_price/`)
            .then(response => {
                // 'updated_selling_price_database': [prev_selling_price_data, latest_selling_price_data, difference_selling_price_data]}
                const updated_selling_price_database = response.data.updated_selling_price_database[2];
                const upatedSPDataMap = updated_selling_price_database.map(firstRow => {
                    return {
                        itemNumber: firstRow[0],
                        description: firstRow[1],
                        category: firstRow[2],
                        itemState: firstRow[3],
                        itemType: firstRow[4],
                        sellingPrice: firstRow[5],
                        state: firstRow[6]
                    };
                });
                setUpdatedSPData(upatedSPDataMap);

                setMessage(response.data.message); // Update the state with the script output
            })
            .catch(error => {
                console.error('Error:', error);
                setMessage('Failed to get script output');
            });
    };

    useEffect(() => {
        if (!isPageLoaded) {
            setIsPageLoaded(true);
            getInvoiceList();
            handleEndOfShift(); // Automatically load 'End of Shift' data
        }
        if (isAuthenticating) {
            const checkAuthCompletion = setInterval(() => {
                if (localStorage.getItem("authComplete") === "true") {
                    console.log("Authentication completed");
                    localStorage.removeItem("authComplete");
                    setIsAuthenticating(false); // This will trigger this useEffect again, allowing for cleanup
    
                    // Proceed with additional logic as needed
                    setMessage("Authentication complete, retrying now...!")
                    setNeedsReAuth(false);
                    if (activeComponent === 'analyze-order') {
                        handleAnalyzeOrderClick();
                    } else {
                        handleRefreshInvoices();
                    }
                }
            }, 1000);
    
            // Cleanup for this interval
            return () => clearInterval(checkAuthCompletion);
        }
    }, [isAuthenticating]); // Effect depends on isAuthenticating    
    
    const handleViewerClick = () => {
        setActiveComponent('planogram_viewer');
    };

    const handleConfigClick = () => {
        setActiveComponent('config');
    };

    const handleScannerClick = () => {
        setActiveComponent('planogram_scanner');
    };


    return (
        <div>
            <CustomNavbar
                user={user}
                navExpanded={navExpanded}
                toggleNavbar={toggleNavbar}
                handleNavSelect={handleNavSelect}
                invoiceOrders={invoiceOrders}
                handleFetchOrderDetails={handleFetchOrderDetails}
            />
            <div className="container mt-3">
                <p>{message}</p> {/* Display the message here */}
                {needsReAuth && 
                    <p><button className="btn btn-primary mb-2" // Bootstrap button classes
                    onClick={handleReAuth}>Click to Reauthenticate</button></p>
                }
            </div>

            {activeComponent === 'planogram_viewer' && (
                <PlanogramViewer user={user} setMessage={setMessage}/>
            )}
            {activeComponent === 'planogram_scanner' && (
                <PlanogramScanner user={user} setMessage={setMessage}/>
            )}

            {activeComponent === 'cost_price_database' && (
                <div className='table-section'>
                    <ProductDataVisualization data={costPriceData} />
                </div>
            )}
            {activeComponent === 'verify-order' && (
                <div>
                   <VerifyOrderComponent user={user} orderId={invoiceOrderId} initialOrderData={orderData} setMessage={setMessage}/>
                </div>
            )}
            {activeComponent === 'analyze-order' && (
                <AnalyzeOrderComponent
                    dropShippedItemsData={dropShippedItemsData}
                    mismatchedItemsData={mismatchedItemsData}
                    notDeliveredItemsData={notDeliveredItemsData}
                />
            )}
            {activeComponent === 'updated_selling_price_database' && (
                <div className='table-section'>
                    <DynamicTable data={updatedSellingPriceData} />
                </div>
            )}

            {activeComponent === 'missing_items_agile' && (
                <div className='table-section'>
                    <ProductDataVisualization data={missingAgileItemsData}/>
                </div>
            )
            }


            {activeComponent === 'updated_selling_price_viewer' && (
                <div>
                    <DynamicTable data={updatedSPData} />
                </div>
            )}


            {activeComponent === 'invoice_viewer' && (
                <div>
                    <DynamicTable data={orderData} universalStyleRule={universalStyleRule} />
                </div>
            )}
            {activeComponent === 'config' && (
                <div>
                    <LoginDetailsForm />
                </div>
            )}

            {activeComponent === 'end_of_shift_component' && (
                <div>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check 
                            type="checkbox" 
                            label="Today's Open Sales" 
                            checked={isUnArchived} 
                            onChange={handleCheckboxChange} 
                        />
                    </Form.Group>
                    <InputGroup className="mb-3">
                        <Form.Control 
                            type="date" 
                            value={date_from} 
                            onChange={handleDateChange('startDate')} 
                            disabled={isUnArchived} 
                        />
                        {/* <Form.Control 
                            type="date" 
                            value={date_to} 
                            onChange={handleDateChange('endDate')} 
                            disabled={isUnArchived} 
                        /> */}
                    </InputGroup>
                    <button className='btn btn-dark' onClick={handleGetEndOfShift}>Refresh</button>
                    <EndOfShiftComponent sales={salesData} message={salesMessage}/>
                </div>
            )}
            {activeComponent === 'dynamic_table_viewer' && (
                <div>
                    <DynamicTable data={dynamicTableData} />
                </div>
            )}
            {activeComponent === 'profit_calc_component' && (
                <ProfitCalcComponent
                    isUnArchived={isUnArchived}
                    date_from={date_from}
                    date_to={date_to}
                    handleCheckboxChange={handleCheckboxChange}
                    handleProfitDateChange={handleProfitDateChange}
                    handleGetProfitCalc={handleGetProfitCalc}
                    profitData={profitData}
                    profitHighlightRule={profitHighlightRule}
                />
            )}
            {activeComponent === 'instant_tickets' && (
                <InstantTicketsComponent
                    handleInstantTicketQuantityReset={handleInstantTicketQuantityReset}
                    handleInstantTicketQuantitySave={handleInstantTicketQuantitySave}
                    ticketData={ticketData}
                    handleInstantTicketUnActivatedQuantityChange={handleInstantTicketUnActivatedQuantityChange}
                    handleInstantTicketActivatedQuantityChange={handleInstantTicketActivatedQuantityChange}
                    handleInstantTicketCountChange={handleInstantTicketCountChange}
                />
            )}

        </div>
    );
};

export default Menu;
