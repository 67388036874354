// BarcodeScannerModal.js
import React, { useState, useEffect } from 'react';
import Quagga from 'quagga'; // Make sure Quagga is properly installed and imported

const BarcodeScannerModal = ({ onClose, onDetected }) => {
  useEffect(() => {
    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: "#scanner-container",
        constraints: {
          facingMode: "environment"
        },
      },
      decoder: {
        readers: [
          "code_128_reader", 
          "ean_reader", 
          "ean_8_reader", 
          "upc_reader",
           "upc_e_reader"
        ]
      },
    }, (err) => {
      if (err) {
        console.error(err);
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected((data) => {
      onDetected(data.codeResult.code);
    });

    return () => {
      Quagga.offDetected();
      Quagga.stop();
    };
  }, [onDetected]);

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 10 }}>
      <button onClick={onClose} style={{ position: 'absolute', top: 20, right: 20 }}>Close Scanner</button>
      <div id="scanner-container" style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default BarcodeScannerModal;
