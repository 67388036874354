import React from 'react';
import PDF from 'react-pdf-js';

const PdfViewer = ({ file }) => {
  return (
    <div className="pdf-viewer-container">

      <iframe src={file} className="pdf-viewer-iframe" width="auto" height="auto" title="PDF Viewer"></iframe>
    </div>

  );
};

export default PdfViewer;
